import React, { useState } from "react";
import inde from "../../../assets/indeicon.svg";
import Button from "../../universal/Button";
import HoverInfo from "./HoverInfo";

function Calculator({ curr }) {
  const [sourceCurrency, setSourceCurrency] = useState("EUR");
  const [targetCurrency, setTargetCurrency] = useState("HUF");
  const [amount, setAmount] = useState(0);
  const [finalValue, setFinalValue] = useState(null);
  const [resultInfos, setResultInfos] = useState({
    from: "",
    to: "",
    amount: null,
  });
  const [apro, setApro] = useState(false);

  if (/* !curr */ true) {
    return (
      <div className="flex flex-col">

        <div className=" flex flex-col w-full sm:w-[80%] mt-16 lg:mt-0  px-8 py-6 bg-[#181818]  rounded-[8px] border-[#2e2e2e] border shadoww lg:w-[430px] duration-300 gap-6 self-center">
          <div className="flex justify-between items-center">
            <h2 className="font-bold text-[20px]">Kalkulátor</h2>
            {/* <HoverInfo>Hiba történt a betöltés során...</HoverInfo> */}
            <HoverInfo>Fejlesztés alatt...</HoverInfo>
          </div>
          <div className="flex flex-col gap-4">
            <div className="h-[30px] w-[40%] rounded-[8px] bg-[#232323] animate-pulse"></div>
            <div className="h-[30px] rounded-[8px] bg-[#232323] animate-pulse"></div>
            <div className="h-[30px] rounded-[8px] bg-[#232323] animate-pulse"></div>
            <div className="h-[30px] w-[40%] rounded-[8px] bg-[#232323] animate-pulse mt-6"></div>
            <div className="h-[30px] rounded-[8px] bg-[#232323] animate-pulse"></div>
            <div className="h-[30px] rounded-[8px] mt-3 bg-[#232323] animate-pulse"></div>
          </div>
        </div>
        <h3 className="max-w-[700px] lg:max-w-[400px] lg:text-left self-center lg:self-auto mt-4 text-center text-sm gry">*A számított összeg 0,9%, de maximum 40.000 Ft kezelési költséget tartalmaz.</h3>
      </div>
    );
  }

  const data = curr.rates;

  function getCurrencyRate(currencyName, data) {
    return data?.find((currency) => currency.name === currencyName);
  }

  function calculateValue(sourceCurrency, targetCurrency, amount, data) {
    let value;
    let rate;
    let step = getCurrencyRate(targetCurrency, data)?.step;

    if (sourceCurrency === "HUF") {
      rate = getCurrencyRate(targetCurrency, data)?.sell;
      value = Math.floor(amount / rate / step) * step;
    } else if (targetCurrency === "HUF") {
      if (sourceCurrency === "USD" || sourceCurrency === "RON") {
        rate = getCurrencyRate(sourceCurrency, data)?.buy;
        value = Math.round((amount * rate) / 5) * 5;
      } else {
        rate = getCurrencyRate(sourceCurrency, data)?.buy;
        value = Math.floor((rate * amount) / step) * step;
      }
    } else {
      rate = getCurrencyRate(sourceCurrency, data)?.buy;
      value = rate * amount;
      rate = getCurrencyRate(targetCurrency, data)?.sell;
      value = Math.floor(value / rate / step) * step;
    }

    return value;
  }

  const Result = () => {
    let value = calculateValue(sourceCurrency, targetCurrency, amount, data);
    setApro(false);
    setResultInfos({
      from: "",
      to: "",
      amount: null,
    });
    if (amount <= 0) {
      setFinalValue("Kérjük, adjon meg egy érvényes összeget.");
      return;
    }

    if (sourceCurrency === targetCurrency) {
      setFinalValue("Kérjük, válasszon különböző valutákat.");
      return;
    }

    if (targetCurrency === "HUF") {
      setResultInfos({
        from: sourceCurrency,
        to: targetCurrency,
        amount: amount.toLocaleString("hu-HU", {
          useGrouping: true,
          maximumFractionDigits: 0,
        }),
      });
    }
    if (sourceCurrency === "HUF") {
      let rate = getCurrencyRate(targetCurrency, data)?.sell;
      setResultInfos({
        from: sourceCurrency,
        to: targetCurrency,
        amount: (value * rate)?.toLocaleString("hu-HU", {
          useGrouping: true,
          maximumFractionDigits: 0,
        }),
      });
    }

    if (sourceCurrency !== "HUF" && targetCurrency !== "HUF") {
      let rate1 = getCurrencyRate(sourceCurrency, data)?.buy;
      let rate2 = getCurrencyRate(targetCurrency, data)?.sell;
      setApro(
        (amount * rate1 - value * rate2)?.toLocaleString("hu-HU", {
          useGrouping: true,
          maximumFractionDigits: 0,
        })
      );
      setResultInfos({
        from: sourceCurrency,
        to: targetCurrency,
        amount: amount.toLocaleString("hu-HU", {
          useGrouping: true,
          maximumFractionDigits: 0,
        }),
      });

      setFinalValue(
        value?.toLocaleString("hu-HU", {
          useGrouping: true,
          maximumFractionDigits: 0,
        })
      );
      return;
    }

    setFinalValue(
      value?.toLocaleString("hu-HU", {
        useGrouping: true,
        maximumFractionDigits: 0,
      })
    );
  };
  const increase = () => {
    const step = getCurrencyRate(targetCurrency, data)?.step;
    const rates = getCurrencyRate(targetCurrency, data)?.sell;
    if (!step || !rates) {
      return; // Data is not available yet, exit early to avoid errors
    }
    const newAmount =
      Math.floor(amount / rates / step) * step * rates + step * rates;
    setAmount(newAmount);
    const newConvertedValue = calculateValue(
      sourceCurrency,
      targetCurrency,
      newAmount,
      data
    );
    setFinalValue(
      newConvertedValue?.toLocaleString("hu-HU", {
        useGrouping: true,
        maximumFractionDigits: 0,
      })
    );
    setResultInfos((prev) => ({
      from: sourceCurrency,
      to: targetCurrency,
      amount: newAmount?.toLocaleString("hu-HU", {
        useGrouping: true,
        maximumFractionDigits: 0,
      }),
    }));
  };
  const decrease = () => {
    const step = getCurrencyRate(targetCurrency, data)?.step;
    const rates = getCurrencyRate(targetCurrency, data)?.sell;
    if (!step || !rates || amount < rates) {
      return;
    }
    const newAmount =
      Math.floor(amount / rates / step) * step * rates - step * rates;
    setAmount(newAmount);
    const newConvertedValue = calculateValue(
      sourceCurrency,
      targetCurrency,
      newAmount,
      data
    );
    setFinalValue(
      newConvertedValue?.toLocaleString("hu-HU", {
        useGrouping: true,
        maximumFractionDigits: 0,
      })
    );
    setResultInfos((prev) => ({
      from: sourceCurrency,
      to: targetCurrency,
      amount: newAmount?.toLocaleString("hu-HU", {
        useGrouping: true,
        maximumFractionDigits: 0,
      }),
    }));
  };

  return (
    <div className="flex flex-col">
      <div className=" flex flex-col w-full sm:w-[80%] mt-16 lg:mt-0  px-8 py-6 bg-[#1f1f1f] rounded-[8px] border-[#2e2e2e] border shadoww lg:w-[430px] duration-300 gap-6 self-center">
        <div className="flex justify-between items-center">
          <h2 className="font-bold text-[20px]">Kalkulátor</h2>
          <HoverInfo>
            Ez a kalkulátor valós időben, a pillanatnyi árfolyamainkkal, a
            célvaluta egészére kerekítve számol.
            <br /> Kérjük, vegye figyelembe, hogy az árfolyamok változóak és csak
            tájékoztató jellegűek, mivel árfolyamaink naponta akár többször is
            változhatnak a piaci mozgásokkal párhuzamosan.
          </HoverInfo>
        </div>

        <h2>Erről a valutáról</h2>
        <select
          className="bg-[#242424] p-2 border border-[#2e2e2e] gry rounded-[4px] -mt-3"
          onChange={(e) => setSourceCurrency(e.target.value)}
          value={sourceCurrency}
        >
          {data?.map((currency) => (
            <option key={currency.name} value={currency.name}>
              {currency.text}
            </option>
          ))}
        </select>
        <input
          className="bg-[#242424] py-2 px-4 border border-[#2e2e2e] text-white ar rounded-[4px] -mt-2"
          placeholder="Átváltandó mennyiség..."
          type="number"
          min="0"
          step="1"
          onChange={(e) => setAmount(parseInt(e.target.value))}
        ></input>
        <h2>Erre a valutára</h2>
        <select
          className="bg-[#242424] p-2 border border-[#2e2e2e] gry rounded-[4px] -mt-3"
          onChange={(e) => setTargetCurrency(e.target.value)}
          value={targetCurrency}
        >
          {data?.map((currency) => (
            <option key={currency.name} value={currency.name}>
              {currency.text}
            </option>
          ))}
        </select>
        {finalValue && (
          <div>
            {resultInfos.amount && (
              <div className="gry ar words2">
                {resultInfos.amount} {resultInfos.from} =
              </div>
            )}
            <div className="font-bold ar justify-between flex ">
              <div className="text-[24px] tracking-tighter words">
                {finalValue} {resultInfos.to} {apro && `+ ${apro} HUF`}
              </div>
              <div></div>
            </div>
            {sourceCurrency === "HUF" && resultInfos.from === "HUF" && (
              <div className="flex justify-around mt-3  gap-5 select-none">
                <div
                  className="bg-[#242424]  border-[#2e2e2e] flex justify-center items-center border w-[50%] py-1 rounded-[8px] hover:bg-[#1a1a1a] duration-200 cursor-pointer"
                  onClick={decrease}
                >
                  <img
                    src={inde}
                    className=" h-[24px] rotate-180 select-none"
                    alt=""
                  ></img>
                </div>
                <div
                  className="bg-[#242424] flex justify-center items-center border-[#2e2e2e] border w-[50%] py-1 rounded-[8px] hover:bg-[#1a1a1a] duration-200 cursor-pointer"
                  onClick={increase}
                >
                  <img src={inde} className=" h-[24px] select-none" alt=""></img>
                </div>
              </div>
            )}
          </div>
        )}
        <Button
          text="Átváltás"
          click={() => {
            Result();
          }}
        ></Button>
        {/* <img src={illustration}></img> */}
      </div>
      <h3 className="max-w-[700px] lg:max-w-[400px] lg:text-left self-center lg:self-auto mt-4 text-center text-sm gry">*A számított összeg 0,9%, de maximum 40.000 Ft kezelési költséget tartalmaz.</h3>
    </div>
  );
}

export default Calculator;
